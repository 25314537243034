import _axios from './request';
import v3request from './v3request';
import { buildParams } from '@/utils/sign';

//抢红包列表
export function getRedBagList(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagGrap/list',
    params: data,
  });
}

//抢红包接口
export function getRedBagReceive(data) {
  data = buildParams(data);
  return v3request({
    method: 'post',
    url: '/api/v1/AppRedbagGrap/receive',
    data,
  });
}

//用户总红包明细
export function getUserRecordList(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagGrap/user_record',
    params: data,
  });
}

//创建红包接口
export function createGrabRedBag(data) {
  data = buildParams(data);
  return v3request({
    method: 'post',
    url: '/api/v1/AppRedbagGrap/create',
    data,
  });
}

//拼手气红包下拉配置数据
export function getRedBagOption(data) {
  data = buildParams(data);
  return v3request({
    method: 'GET',
    url: '/api/v1/AppRedbagGrap/option',
    params: data,
  });
}

//获取领取红包详情
export function getRedBagReceiveDetail(data) {
  data = buildParams(data);
  return v3request({
    method: 'GET',
    url: '/api/v1/AppRedbagGrap/detail',
    params: data,
  });
}
//获取红包创建详情
export function getRedBagCreateInfo(data) {
  data = buildParams(data);
  return v3request({
    method: 'GET',
    url: '/api/v1/AppRedbagGrap/info',
    params: data,
  });
}
//获取是否是主播账号
export function getGrabUserInfo(data) {
  data = buildParams(data);
  return v3request({
    method: 'GET',
    url: '/api/v1/AppRedbagGrap/user',
    params: data,
  });
}
//获取直播红包活动总开关
export function getRedBagSwitch(data) {
  data = buildParams(data);
  return v3request({
    method: 'GET',
    url: '/api/v1/AppRedbagGrap/config',
    params: data,
  });
}
